<template>
  <div class="q-pa-md" :style="[{ height: tabHeight }, { 'overflow-y': 'auto' }]">
    <q-timeline class="moc-timeline" color="green-4">
      <q-timeline-entry
        v-for="(item, idx) in items"
        :key="idx"
        :heading="item.heading" :class="{ 'q-pb-xs': item.heading }"
        :icon="item.icon">
        <template v-if="item.title" v-slot:title>
          <b class="text-blue-14">[{{item.title}}]</b>
        </template>
        <template v-if="item.subtitle" v-slot:subtitle>
          {{item.subtitle}}
        </template>
        <q-slide-transition v-if="!item.heading">
          <div>
            <component
              :is="item.component"
              :param="param"
              @setAssessmentName="setAssessmentName"
            />
          </div>
        </q-slide-transition>
      </q-timeline-entry>
    </q-timeline>
  </div>
</template>

<script>
export default {
  name: 'jsaOnePageDetail',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      assessmentName: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if (this.editable && !this.disabled) {
        numHeight = numHeight - 20
      }
      return numHeight + 'px';
    },
    items() {
      return [
        // 평가계획
        { title: this.$language('평가계획'), component: () => import(`${'./timeline/jsaPlanTL.vue'}`), },
        // 위험성 추정 및 결정
        { title: this.$language('위험성 추정 및 결정'), component: () => import(`${'./timeline/jsaScenarioTL.vue'}`), },
        // 감소대책 계획 및 실행
        { title: this.$language('감소대책 계획 및 실행'), component: () => import(`${'./timeline/jsaRiskRegisterTL.vue'}`), },
      ]
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    setAssessmentName(assessmentName) {
      this.assessmentName = assessmentName;
    },
  }
};
</script>
<style lang="sass">
.moc-timeline
  max-height: 550px
  .q-timeline__heading-title
    padding-bottom: 10px

</style>